import '../styles/globals.scss'
import 'antd/dist/antd.css'
import 'intro.js/introjs.css'

import { CacheProvider } from '@emotion/react'
import ThemeProvider from '@mui/system/ThemeProvider/ThemeProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar'

import { AuthProvider } from '../hooks/useAuth'
import createEmotionCache from '../utils/cache.emotion'
import theme from '../utils/theme.material'

const clientSideEmotionCache = createEmotionCache()

const MyApp = ({ Component, pageProps }: AppProps) => (
    <>
        <Head>
            <title>timelinefyi</title>
            <link href="/favicon.ico" rel="icon" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
            <meta charSet="utf-8" />
            <script
                async
                defer
                data-website-id="26ea489b-3238-4042-b3c0-35f760b7fb6f"
                src="https://timelinefyi-umami-m4mx1qts8-timelinefyi.vercel.app/umami.js"
            />
        </Head>
        <NextNProgress />
        <CacheProvider value={clientSideEmotionCache}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <AuthProvider>
                        <Component {...pageProps} />
                    </AuthProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </CacheProvider>
    </>
)

export default MyApp
