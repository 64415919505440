import axios from 'axios'

const errorHandler = (error: any) => {
    if (axios.isAxiosError(error) && error.config.url !== '/auth/refresh') {
        throw error
    }
    Promise.resolve(error)
}

const client = axios.create({
    baseURL: `${process.env.BACKEND_SERVER_URL}/api/v1`,
    withCredentials: true,
})

client.interceptors.response.use(
    (resp) => resp,
    async (error) => {
        if (axios.isAxiosError(error)) {
            const { config } = error
            if (
                config.url !== '/auth/signin' &&
                config.url !== '/auth/signup' &&
                config.url !== '/auth/refresh'
            ) {
                // Access Token was expired
                if (error.response?.status === 401) {
                    try {
                        await client.post('/auth/refresh')
                        return axios.request(config)
                    } catch (_error) {
                        return errorHandler(_error)
                    }
                }
            }
        }

        return errorHandler(error)
    },
)

export default client
