import type {
    ResetPasswordPayload,
    SigninPayload,
    SignupPayload,
    UpdateCredential,
    User,
} from '@timelinefyi/types'

import client from '../client'
import { Token } from '../types'

const signin = async (payload: SigninPayload): Promise<Token> => {
    const response = await client.post(`/auth/signin`, payload)
    return response?.data?.token
}

const signup = async (payload: SignupPayload): Promise<User> => {
    const response = await client.post(`/auth/signup`, payload)
    return response?.data?.token
}

const signout = async (): Promise<void> => {
    await client.post(`/auth/signout`)
}

const refresh = async (): Promise<Token> => {
    const response = await client.post(`/auth/refresh`)
    return response?.data?.token
}

const changePassword = async (payload: UpdateCredential) => {
    await client.put(`/auth/password`, payload)
}

const sendResetPasswordEmail = async (email: string) => {
    await client.post('/auth/forget', { email })
}

const getChangePasswordRequest = async (id: string) => {
    await client.get(`/auth/forget/${id}`)
}

const resetPassword = async (payload: ResetPasswordPayload) => {
    await client.put(`/auth/forget/reset`, payload)
}

export const AuthApiClient = {
    signin,
    signup,
    signout,
    refresh,
    changePassword,
    sendResetPasswordEmail,
    getChangePasswordRequest,
    resetPassword,
}
