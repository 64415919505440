import type { UpdateUser, User, UserId } from '@timelinefyi/types'

import client from '../client'

const get = async (): Promise<User> => {
    const response = await client.get(`/users`)
    return response?.data?.data
}

const getUser = async (userId: UserId): Promise<User> => {
    const response = await client.get(`/users/${userId}`)
    return response?.data?.data
}

const update = async (updateUser: UpdateUser): Promise<User> => {
    const response = await client.put(`/users`, updateUser)
    return response?.data?.data
}

export const UserApiClient = {
    get,
    getUser,
    update,
}
