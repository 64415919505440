import type { User } from '@timelinefyi/types'
import { message } from 'antd'
import { useRouter } from 'next/router'
import React from 'react'

import { AuthApiClient } from '../utils/api/clients/auth.client'
import { UserApiClient } from '../utils/api/clients/user.client'

interface AuthContextType {
    user?: User
    setUser: React.Dispatch<React.SetStateAction<User | undefined>>
    loading: boolean
    isAdmin: boolean
    error?: any
    signin: (email: string, password: string) => void
    signup: (email: string, username: string, password: string) => void
    signout: () => void
}

const AuthContext = React.createContext<AuthContextType>({} as AuthContextType)

type AuthProviderProps = {
    children: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const router = useRouter()
    const [user, setUser] = React.useState<User>()
    const [error, setError] = React.useState<any>()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [loadingInitial, setLoadingInitial] = React.useState<boolean>(true)

    // If we change page, reset the error state.
    React.useEffect(() => {
        if (error) setError(null)
    }, [router.pathname, error])

    // Check if there is a currently active session
    // when the provider is mounted for the first time.
    //
    // If there is an error, it means there is no session.
    //
    // Finally, just signal the component that the initial load
    // is over.
    React.useEffect(() => {
        UserApiClient.get()
            .then((userData) => setUser(userData))
            .catch((_error) => {})
            .finally(() => setLoadingInitial(false))
    }, [])

    // Flags the component loading state and posts the login
    // data to the server.
    //
    // An error means that the email/password combination is
    // not valid.
    //
    // Finally, just signal the component that loading the
    // loading state is over.
    const signin = React.useCallback(
        (email: string, password: string) => {
            setLoading(true)

            AuthApiClient.signin({ email, password })
                .then(() => {
                    UserApiClient.get().then((userData) => {
                        message.success('Success!')
                        setUser(userData)
                        if (
                            document.referrer.startsWith(
                                `${process.env.FRONTEND_URL}`,
                            ) &&
                            document.referrer !==
                                `${process.env.FRONTEND_URL}/signup` &&
                            document.referrer !==
                                `${process.env.FRONTEND_URL}/login` &&
                            document.referrer !== `${process.env.FRONTEND_URL}`
                        ) {
                            router.back()
                        } else {
                            router.push('/')
                        }
                    })
                })
                .catch((_error) => setError(_error))
                .finally(() => setLoading(false))
        },
        [router],
    )

    // Sends sign up details to the server. On success, we just apply
    // the created user to the state.
    const signup = React.useCallback(
        (email: string, username: string, password: string) => {
            setLoading(true)

            AuthApiClient.signup({ email, username, password })
                .then((_) => {
                    signin(email, password)
                })
                .catch((_error) => {
                    setError(_error)
                })
                .finally(() => setLoading(false))
        },
        [signin],
    )

    // Call the logout endpoint and then remove the user
    // from the state.
    const signout = React.useCallback(() => {
        setLoading(true)
        AuthApiClient.signout()
            .catch((_error) => setError(_error))
            .finally(() => {
                setUser(undefined)
                setLoading(false)
                router.replace('/')
                message.success('Success!')
            })
    }, [router])

    // Make the provider update only when it should.
    // We only want to force re-renders if the user,
    // loading or error states change.
    //
    // Whenever the `value` passed into a provider changes,
    // the whole tree under the provider re-renders, and
    // that can be very costly! Even in this case, where
    // you only get re-renders when logging in and out
    // we want to keep things very performant.
    const authValue = React.useMemo(() => {
        const isAdmin = user?.id === process.env.ADMIN_USER_ID
        return {
            user,
            setUser,
            loading,
            isAdmin,
            error,
            signin,
            signup,
            signout,
        }
    }, [user, loading, error, signin, signup, signout])

    // We only want to render the underlying app after we
    // assert for the presence of a current user.
    return (
        <AuthContext.Provider value={authValue}>
            {!loadingInitial && children}
        </AuthContext.Provider>
    )
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
const useAuth = () => React.useContext(AuthContext)
export default useAuth
